// 终端 终端列表的固定表格第一行
export const listColumns = [{
    title: "#",
    dataIndex: "id",
    slots: { customRender: "serialNumber" },
    width: 70,
    align: 'center',
    fixed: 'left',
},
{
    title: "版本",
    dataIndex: "taskTVersion",
    slots: { customRender: "taskTVersion" },
    fixed: 'left',
    // width: 180,
},
{
    title: "启用状态",
    dataIndex: "status",
    slots: { customRender: "status" },
    // width: 180,
},
{
    title: "启用时间",
    dataIndex: "enableAt",
    slots: { customRender: "enableAt" },
    // width: 180,
},
{
    title: "停用时间",
    dataIndex: "disableAt",
    slots: { customRender: "disableAt" },
    // width: 180,
},
{
    title: "任务模板名称",
    dataIndex: "taskTitle",
    slots: { customRender: "taskTitle" },
    width: 300,
},
{
    title: "任务类型",
    dataIndex: "typeName",
    slots: { customRender: "typeName" },
    // width: 180,
},
{
    title: "操作",
    dataIndex: "handle",
    slots: { customRender: "handle" },
    fixed: 'right',
    align: 'center',
    width: 165,
},
]