
import { defineComponent, createVNode, h } from "vue";
import {
  TagsFilled,
  SyncOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { getList, updateStatus, updateEnable } from "@/api/template/api.js";
import { listColumns } from "@/api/template/fixedData.js";
import Screen from "@/components/list/Screen.vue";
import TableTab from "@/components/list/TableTab.vue";
import Pagination from "@/components/list/Pagination.vue";
import Edit from "@/components/icon/Edit.vue";
import { Modal } from "ant-design-vue";

export default defineComponent({
  components: {
    TagsFilled,
    SyncOutlined,
    SearchOutlined,
    ExclamationCircleOutlined,
    Screen,
    TableTab,
    Pagination,
    Edit,
  },
  data() {
    return {
      formState: {},
      //table的数据
      data: [],
      //table的列名字
      columns: listColumns,
      //总数据量
      total: 0,
      searchFlag: false,
      //table的布局
      scroll: { x: "100%" },
      //批量操作按钮加载
      loading: true,
      //是否是租户
      isTenant: location.pathname === "/template",
    };
  },
  methods: {
    //跳转到编辑
    toDetails(id, type) {
      let path = "/template";
      if (this.$route.path.includes("templateApp")) {
        path = "/templateApp";
        if (id === undefined) {
          id = "new";
          (this as any).$router.push(`${path}/created1/${id}`);
        } else {
          if (type === "edit") {
            (this as any).$router.push(`${path}/created1/${id}`);
          } else {
            (this as any).$router.push(`${path}/details1/${id}`);
          }
        }
      } else {
        if (id === undefined) {
          id = "new";
          (this as any).$router.push(`${path}/created/${id}`);
        } else {
          if (type === "edit") {
            (this as any).$router.push(`${path}/created/${id}`);
          } else {
            (this as any).$router.push(`${path}/details/${id}`);
          }
        }
      }
    },
    changePagination(page) {
      this.getData(page.pageSize, page.pageNum);
    },
    //获取列表
    getData(pageSize = 10, pageNum = 1) {
      this.loading = true;
      getList({
        version: (this as any).formState.version,
        status: (this as any).formState.status,
        activeStartTime:
          (this as any).formState.activeTime &&
          (this as any).formState.activeTime[0] &&
          (this as any).formState.activeTime[0] + ":00",
        activeEndTime:
          (this as any).formState.activeTime &&
          (this as any).formState.activeTime[1] &&
          (this as any).formState.activeTime[1] + ":59",
        downStartTime:
          (this as any).formState.downTime &&
          (this as any).formState.downTime[0] &&
          (this as any).formState.downTime[0] + ":00",
        downEndTime:
          (this as any).formState.downTime &&
          (this as any).formState.downTime[1] &&
          (this as any).formState.downTime[1] + ":59",
        pageSize: pageSize,
        pageNum: pageNum,
      })
        .then((res: any) => {
          // res.data.data[0].status = 0;
          // res.data.data[1].status = 1;
          // res.data.data[2].status = 2;
          let result = res.data;
          this.total = result.total;
          this.data = result.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //搜索
    search() {
      this.getData();
    },
    //删除
    del(record) {
      let that = this;
      Modal.confirm({
        title: "提示",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: "是否删除此模板",
        okText: "确认",
        cancelText: "取消",
        onOk() {
          updateStatus(record.id).then((res: any) => {
            if (res.data.success) {
              (that as any).$message.success("删除成功");
              that.init();
            }
          });
        },
        onCancel() {},
      });
    },
    //启用
    enable(record) {
      console.log(record);
      let that = this;
      Modal.confirm({
        title: "提示",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: h("div", null, [
          h("span", null, `是否要启用模板：`),
          h(
            "span",
            { style: "color: #5a80b0" },
            `${record.taskTitle}-${record.taskTVersion}`
          ),
          h(
            "span",
            null,
            `，启用后${
              record.status == 2 ? "该模板不可再次编辑且" : ""
            }原使用中的模板状态会被修改为已停用`
          ),
        ]),
        okText: "启用",
        cancelText: "取消",
        onOk() {
          updateEnable({
            id: record.id,
            typeCode: record.taskTypeCode,
          }).then((res: any) => {
            if (res.data.success) {
              (that as any).$message.success("启用成功");
              that.init();
            }
          });
        },
        onCancel() {},
      });
    },
    init() {
      //获取终端列表信息
      this.getData(
        Number(
          sessionStorage.getItem(this.$route.path.replace(/\//g, "") + "Size")
        ) || 10,
        Number(
          sessionStorage.getItem(this.$route.path.replace(/\//g, "") + "Num")
        ) || 1
      );
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    addBtn() {
      if (this.isTenant) {
        return false;
      } else {
        return true;
      }
    },
  },
});
